// This is made to support multiple resize callbacks. When one of your scripts
// needs to listen to window.onresize, subscribe instead to this resize
// observer by using the window.onresizeObserver.subscribe() method. Pass your
// callback as a parameter.

window.onresizeObserver = {
  callbacks: [],

  subscribe: function(callback) {
    this.callbacks.push(callback)
  }
}

window.onresize = function(e) {
  for (const callback of window.onresizeObserver.callbacks) {
    callback(e)
  }
}
