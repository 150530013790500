// This is made to support multiple onclick functions. When one of your scripts
// needs to listen to document.onclick, subscribe instead to this onlick
// observer by using the window.onclickObserver.subscribe() method. Pass your
// callback as a parameter.

window.onclickObserver = {
  callbacks: [],

  subscribe: function(callback) {
    this.callbacks.push(callback)
  }
}

document.onclick = function(e) {
  e.stopPropagation()

  for (const callback of window.onclickObserver.callbacks) {
    callback(e)
  }
}
